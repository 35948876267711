.Typist {
  display: inline;
}

li.active > a {
  color: #bf2128 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #bf2128 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #bf2128 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 85, 212, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;

  &:hover {
    background-color: $primary !important;
    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $primary !important;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}

.mdi-cog::before {
  content: "\F493" !important;
}
.custom-dots {
  .indicator-active {
    background-color: #bf2128 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

